.App {
  font-family: sans-serif;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  }
  @media screen and (max-width : 450px)
{
  .walletInfo
  {
    font-size:4.2vw;
  }
}
.walletInfo{
  font-size:1.4rem;
  background-color: transparent;
  padding: 1.5rem 0;
}