.header {
    width: 100%;
    text-align: center;
    background-color: white;
    color: black;
    height: 15%;
  }
@media (height <= 760px){
  .header{
    height: 104px;
  }
}
.header img{
  width: 40%;
  height: auto;
  max-height: 104px;
  max-width: 614px;
  min-width: 360px;
}
.menu{
  position: absolute;
  top: 30px;
  right: 40px;
  height: auto;
  z-index: 2;
  width: 45px;
  cursor: pointer;
}
.menu img{
  width: 100%;
  height: auto;
  filter:brightness(0.2);
}