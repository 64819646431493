/*@import '~react-toastify/ReactToastify.min.css';*/

.login{
  background-color: #f8a0a06e;
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 20px;
  gap: 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  position:absolute;
}
.figureCSS{
    height: 30%;
    background-color: white;
    width: 100%;
    border-top-color: white;
    border-top-width: 100px;
    border-top-style: solid;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    position: absolute;
    top: 0;
}
.center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: rgb(250,213,239);
  background: linear-gradient(326deg, rgb(250, 213, 239) 0%, rgb(252, 15, 22) 35%, rgb(137, 2, 10) 80%, rgb(253, 253, 253) 99%);
  position: relative;
}
.case{
    background-color: white;
  border-radius: 5px;
  padding: 5px 5px;
  display: flex;
}
.case input{
  background-color: white;
  border: transparent;
  outline: none !important;
}
.caseinput :focus{
  background-color: white;
  outline: none !important;
}
.case img{
    padding-right: 5px;
}
input[type=button]{
    width: 200px;
  height: 35px;
  border: transparent;
  border-radius: 5px;
  background-color: #9c0a0e;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
input[type=submit]{
  width: 200px;
height: 35px;
border: transparent;
border-radius: 5px;
background-color: #9c0a0e;
color: white;
font-weight: bold;
cursor: pointer;
}