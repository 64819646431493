.footer {
    width: 100%;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
    flex: auto;
    padding: 20px 0px;
    height: 10%;
    max-height: 88px;
    text-align: center;
  }
  @media (width <= 760px){
    .header{
      height: 88px;
    }
  }
.footer div{
  width: 25%;
  text-align: center;
}
.selector img{
  cursor: pointer;
}