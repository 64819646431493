.main {
    /*position: absolute;*/
    top: 265px;
    left: 0;
    width: 100%;
    text-align: center;
    color: black;
    background: rgb(250,213,239);
    background: linear-gradient(326deg, rgb(250, 213, 239) 0%, rgb(252, 15, 22) 35%, rgb(137, 2, 10) 80%, rgb(253, 253, 253) 99%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 75%;
  }
  @media (width <= 760px){
    .main{
      height: calc(100% - 88px);
    }
  }
.content{
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  gap:10px;
}
.balance{
  background-color: white;
  border-radius: 10px;
  width: 100%;
  text-align: left;
}
.balance h1, h2, p{
  margin-left: 32px;
}
.balance p{
  background-color: #00bf63;
  border-radius: 4px;
  color: white;
  width: 5%;
  max-width: 120px;
  min-width: 70px;
  padding: 5px;
}
.wallet{
  background-color: white;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.token{
  width: 20%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 32px;
}
.wallet_value{
  width: 40%;
  text-align: left;
}
.wallet_total,.wallet_price{
  
}
.wallet_balance{
  
}